import React from 'react';

import Close from '../../images/icon-close.svg';
import Next from '../../images/icon-angle-right.svg';
import Prev from '../../images/icon-angle-left.svg';

export default function LightboxButton(props) {
  const { children, close, next, prev, style, ...other } = props;
  let buttonImage = false;
  if (close) {
    buttonImage = Close;
  }
  if (next) {
    buttonImage = Next;
  }
  if (prev) {
    buttonImage = Prev;
  }
  const buttonStyles = close
    ? { ...basicStyles, ...closeButton, ...style }
    : { ...basicStyles, ...style };
  return (
    <button
      type="button"
      className="hidden"
      style={{
        ...buttonStyles,
        backgroundImage: `url(${buttonImage})`,
      }}
      {...other}
    >
      {children}
    </button>
  );
}

const basicStyles = {
  fontSize: 0,
  color: `transparent`,
  height: `30px`,
  width: `30px`,
  backgroundRepeat: `no-repeat`,
  backgroundPosition: `center`,
};

const closeButton = {
  position: `fixed`,
  top: `30px`,
  right: `30px`,
  zIndex: 1000,
};
